.module {
  margin-top: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.pageDone {
  color: forestgreen;
}

.pageUndone {
  color: lightgray;
}
