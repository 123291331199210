.other-workshops {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 50px;
  text-align: center;
  color: white;
  font-family: Impact;

  background: radial-gradient(42.49% 63.74% at 50% 50%, #14244C 0%, #000026 100%),
  radial-gradient(42.49% 63.74% at 50% 50%, rgba(20, 36, 76, 0.6) 0%, rgba(0, 0, 38, 0.6) 100%);

  h1 {
    font-size: 2.5em;
  }

  .workshops {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 50px;

    .workshop {
      display: flex;
      flex-direction: column;
      padding: 50px;
      margin: 50px;
      border: 3px solid white;
      width: 400px;
      height: 400px;

      a {
        -webkit-appearance: none;
        font-family: Montserrat;
        text-decoration: none;
        background: #28C580;
        width: 100%;
        border: none;
        color: white;
        font-size: 1em;
        font-weight: 500;
        margin-top: auto;
        padding: 10px;
      }

      .workshop-title {
        padding: 20px;
        letter-spacing: 0.05em;
        font-size: 2em;
      }
    }
  }
}

.other-workshops-light {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 50px;
  text-align: center;
  font-family: Impact;
  background-color: #EFF3F8;

  h1 {
    font-size: 2.5em;
  }

  .workshops {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 50px;

    .workshop {
      display: flex;
      flex-direction: column;
      padding: 50px;
      margin: 50px;
      border: 3px solid white;
      width: 400px;
      height: 400px;

      a {
        -webkit-appearance: none;
        font-family: Montserrat;
        text-decoration: none;
        background: #000042;
        width: 100%;
        border: none;
        color: white;
        font-size: 1em;
        font-weight: 500;
        margin-top: auto;
        padding: 10px;
      }

      .workshop-title {
        padding: 20px;
        letter-spacing: 0.05em;
        font-size: 2em;
      }
    }
  }
}
@media (max-width: 1024px) {
  .other-workshops {
    margin: 0;
    min-height: 600px;

    .workshops {
      .workshop {
        padding: 10px;
        margin: 10px;
        border: 1px solid white;
        width: 300px;
        height: 300px;

        a {
          padding: 5px;
          font-size: 1em;
        }

        .workshop-title {
          margin-top: 1.5em;
          padding: 0;
          font-size: 1.5rem;
        }
      }
    }
  }

  .other-workshops-light {
    min-height: 600px;

    .workshops {
      .workshop {
        padding: 10px;
        margin: 10px;
        border: 1px solid white;
        width: 300px;
        height: 400px;

        a {
          padding: 5px;
          font-size: 1em;
        }

        .workshop-title {
          padding: 0;
          font-size: 1.5em;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .other-workshops {
    margin: 0;
    min-height: 500px;

    .workshops {
      .workshop {
        padding: 10px;
        margin: 10px;
        border: 1px solid white;
        width: 300px;
        height: 300px;

        a {
          padding: 5px;
          font-size: 1em;
        }

        .workshop-title {
          margin-top: 1.5em;
          padding: 0;
          font-size: 1.5em;
        }
      }
    }
  }

  .other-workshops-light {
    min-height: 500px;

    .workshops {
      .workshop {
        padding: 10px;
        margin: 10px;
        border: 1px solid white;
        width: 300px;
        height: 300px;

        a {
          padding: 5px;
          font-size: 1em;
        }

        .workshop-title {
          padding: 0;
          font-size: 1.5em;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .other-workshops {
    .workshops {
      flex-direction: column;
      justify-content: center;
      .workshop {
        height: 250px;
        padding-top: 50px;
        margin: auto auto 50px auto;
        width: 250px;

        .workshop-title {
          margin-top: 0;
        }
      }
    }
  }
  .other-workshops-light {
    .workshops {
      flex-direction: column;
      justify-content: center;
      .workshop {
        height: 250px;
        padding-top: 50px;
        margin: auto auto 50px auto;
        width: 250px;

        .workshop-title {
          margin-top: 0;
        }
      }
    }
  }
}
