.course {
  .chapters {
    padding: 50px;
  }
}

.tileContainer {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  padding: 20px;
}

.chapterInfoContainer {
  .chapterInfo {
    background-color: white;
    margin-top: 10px;
    padding: 10px;
    display: inline-block;
    cursor: pointer;

    a {
      color: black;
      font-weight: bold;
    }

    a:link {
      text-decoration: none;
    }

    a:visited {
      text-decoration: none;
    }

    a:hover {
      text-decoration: none;
    }

    a:active {
      text-decoration: none;
    }

    .modulesLength {
      color: grey;
      font-weight: lighter;
    }
  }
  .currentChapter {
    border-radius: 5px;
    padding: 10px;
    background-color: #f5f5f5;
  }
}
