.login-container {
  height: 100vh;
  background: radial-gradient(42.49% 63.74% at 50% 50%, rgba(20, 36, 76, 0.9) 0%, rgba(0, 0, 38, 0.9) 100%);
}

.login-gradient {
  background: radial-gradient(42.49% 63.74% at 50% 50%, rgba(20, 36, 76, 0.9) 0%, rgba(0, 0, 38, 0.9) 100%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.login-stripes {
  position: absolute;
  right: 0;
  width: 650px;
  height: 100vh;
  overflow-y: hidden;
  background: url("../assets/stripes.png") no-repeat;
  z-index: 0;
}

.login-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 200px;
  width: 657px;
  height: 531px;
  z-index: 2;
  border: 3px solid #FFFFFF;
  box-sizing: border-box;

  h4 {
    font-family: Impact;
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 189.8%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }

  .text-field {
    margin: 20px 0;
  }

  .login-form {
    width: 50%;
    margin: auto;
    font-family: Montserrat;
    input[type="text"] {
      color: white;
      outline: none;
      box-sizing: border-box;
      background: none;
      border: 3px solid white;
      padding: 5px;
    };
    input[type="password"] {
      color: white;
      outline: none;
      box-sizing: border-box;
      background: none;
      border: 3px solid white;
      padding: 5px;
    }

    ::placeholder {
      color: white;
    }

    a {
      color: gray;
    }

    .login-button {
      font-family: Impact;
      text-transform: uppercase;
      background: #28C580;
      padding: 5px;
      border: none;
      color: white;
      font-size: 1.5em;
      letter-spacing: 0.05em;
    }
  }
}

@media (max-width: 480px) {
  .login-stripes {
    display: none;
  }

  .login-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 0;
    width: 657px;
    height: 531px;
    z-index: 2;
    box-sizing: border-box;
    border: none;

    h4 {
      font-size: 22px;
    }

    .login-form {
      width: 40%;
    }
  }
}
