.workshop-description {
  font-family: Montserrat;
  max-width: 1200px;
  margin: auto;

  h1 {
    font-family: Impact;
  }
}

.description-row {
  display: flex;
  padding-top: 50px;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.description-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;

  .card {
    padding: 30px;
    border: none;

    .card-title {
      font-family: Impact;
      font-size: 1.5em;
    }

    .card-content {
      max-width: 600px;
      font-size: 1em;
    }
  }
}

.what-will-learn {
  margin-top: 200px;
}

@media (max-width: 768px) {
  .row {
    padding-top: 0;
  }

  .description-column {
    .card {
      padding: 0 25px 0 10px;

      .card-title {
        font-size: 1.5em;
      }
    }
  }
}

@media (max-width: 480px) {
  .description-row {
    padding-top: 0;
  }

  .description-column {
    flex-basis: 100%;

    .card {
      padding: 10px;

      .card-title {
        font-size: 1.5em;
      }
    }
  }
}
