.priceDetailsContainer {

  background-color: #f5f5f5;
  padding: 20px;

  .amount {
    text-decoration: line-through;
  }

  .discount {
    color: red;
  }

}
