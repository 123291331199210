.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-family: Montserrat;
  font-size: 1.5em;
  background-color: white;
  //text-transform: uppercase;
  width: 100%;
  li {
    padding: 10px;
  }
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

@media (max-width: 768px) {
  .menu {
    display: none;
  }
}

@media (max-width: 480px) {
  .menu {
    display: none;
  }
}
