//@import "styles/skeleton.scss";
@import "App.scss";
@import "Login/Login.scss";
@import "Footer/Footer.scss";
@import "NavBar/NavBar.scss";
@import "NotFound/NotFound.scss";
@import "PasswordDetails/PasswordDetails.scss";
@import "ProfileDetails/ProfileDetails.scss";
@import "Register/Register.scss";
@import "RecoverLostPassword/RecoverLostPassword.scss";
@import "Spinner/Spinner.scss";
@import "Welcome/Welcome.scss";
@import "LandingPages/Gatling.scss";
@import "LandingPages/Trainer.scss";
@import "LandingPages/Testimonials.scss";
@import "LandingPages/Menu.scss";
@import "LandingPages/common/Header/Header.scss";
@import "LandingPages/common/Header/WorkshopHeader.scss";
@import "LandingPages/AkkaTyped/AkkaTyped.scss";
@import "LandingPages/EventSourcing/EventSourcing.scss";
@import "LandingPages/common/Common.scss";
@import "LandingPages/common/Agenda/Agenda.scss";
@import "LandingPages/common/WorkshopDescription/WorkshopDescription.scss";
@import "i18n/components/LanguageSwitcher.scss";
@import "Newsletter/Newsletter.scss";
@import "Welcome/Workshops/MainWorkshop.scss";
@import "Welcome/Workshops/OtherWorkshops.scss";

@font-face {
  font-family: Impact;
  font-style: normal;
  font-weight: 900;
  src: url('./assets/impact.ttf');
}

html, body {
  height: 100%;
  scroll-behavior: smooth;
  scroll-padding-top: 175px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", "Oswald", sans-serif;
  font-size: 16px;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

a {
  cursor: pointer;
}

.validation-message {
  color: crimson;
  margin-bottom: 1em;
}

input[type="submit"]:disabled,
input[type="submit"]:disabled:hover {
  background-color: gainsboro;
  border-color: gainsboro;
  cursor: not-allowed;
}
