.courseCard {
  width: 400px;
  height: 400px;

  img {
    padding: 2rem;
    width: 12rem;
    height: 12rem;
  }
  p {
    overflow: hidden;
    height: 100px;
    line-height: 25px;
  }
  .cardBottom {
    position: absolute;
    bottom: 15px;
  }
}
