.language-switcher {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 100px;
  padding-top: 20px;

  a {
    margin: 5px;
    text-align: center;
    color: whitesmoke;
    width: 50px;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    border: 1px white solid;
  }

  a:link {
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
    color: #FFFFFF;
  }
}

@media (max-width: 768px) {
  .language-switcher {
    margin-right: 0;

    a {
      width: 40px;
      height: 40px;

      h4 {
        font-size: small;
      }
    }
  }
}

@media (max-width: 480px) {
  .language-switcher {
    margin-right: 0;
    a {
      width: 40px;
      height: 40px;

      h4 {
        font-size: small;
      }
    }
  }
}
