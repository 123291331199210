.workshop-header {
  display: flex;
  flex-direction: column;
  background-color: black;
  background-size: cover;
  background-image: url("../../../assets/header_small.png");
  min-height: 100vh;
  width: 100vw;

  .workshop-header-content {
    display: flex;
    flex-direction: row;

    .header-titles {
      display: flex;
      flex-direction: column;
      width: 60vw;
    }

    .workshop-header-trainer {
      img {
        position: absolute;
        bottom: 0;
        height: 90vh;
      }
    }
  }

  .title {
    padding-top: 50px;
    padding-left: 100px;

    font-family: Impact;
    font-style: normal;
    font-weight: 900;
    font-size: 4vw;
    line-height: 132.47%;
    color: #FFFFFF;
  }

  .subtitle {
    padding-top: 50px;
    padding-left: 100px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 1.5vw;
    line-height: 34px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
  }

  .newsletter-container {
    width: 500px;
    padding-left: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .workshop-header {
    background-image: url("../../../assets/header_small.png");
    .workshop-header-content {
      flex-direction: column;
      .header-titles {
        width: 90vw;
      }
      .title {
        padding-top: 30px;
        padding-left: 100px;
        font-size: 3em;
      }

      .subtitle {
        font-size: 1.2em;
        line-height: 34px;
        color: #FFFFFF;
      }
      .workshop-header-trainer {
        display: none;
      }
    }
  }
}

@media (max-width: 480px) {
  .workshop-header {
    display: block;
    min-height: 100vh;
    height: 100%;
    background-image: url("../../../assets/header_small.png");

    .workshop-header-content {
      flex: 1;

      .title {
        padding-top: 0;
        padding-left: 20px;
        font-size: 2em;
      }

      .subtitle {
        padding-left: 20px;
        font-size: 1em;
        line-height: 34px;
        color: #FFFFFF;
      }

      .workshop-header-trainer {
        display: none;
      }
    }
    .newsletter-container {
      width: 300px;
      padding-left: 20px;
      padding-top: 20px;
      top-bottom: 20px;
    }
  }
}

