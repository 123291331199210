.newsletter {
  color: white;

  p {
    font-family: Montserrat;
    font-size: 1rem;
    font-weight: 700;
  }

  .subscribe-form {
    display: flex;
    background: #28C580;

    input[type="text"] {
      outline: none;
      width: 100%;
      color: white;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      line-height: 1.6;

      letter-spacing: 1px;
      background: #28C580;
      padding: 0.5em;
      display: inline-block;
      border: none;
    }

    ::placeholder {
      color: white;
      text-transform: uppercase;
    }

    input[type="submit"] {
      border: none;
      outline: none;
      color: white;
      font-size: 1.2rem;
      background: #28C580 url("../assets/sign-up-arrow.png") right no-repeat;
      width: 10%;
    }
  }
}

@media (max-width: 768px) {
  .newsletter {
    p {
      font-size: 1.2rem;
    }
    .subscribe-form {
      input[type="text"] {
        font-size: 0.8rem;
      }

      input[type="submit"] {
        font-size: 1rem;
        width: 20%;
      }
    }
  }
}

@media (max-width: 480px) {
  .newsletter {
    .subscribe-form {
      height: 40px;
      input[type="text"] {
        font-size: 0.8em;
        width: 350px;
      }

      input[type="submit"] {
        font-size: 1em;
        width: 20%;
      }
    }
  }
}

@media (max-width: 375px) {
  .newsletter {
    p {
      font-size: 1rem;
    }
    .subscribe-form {
      height: 40px;
      input[type="text"] {
        font-size: 0.8rem;
        width: 250px;
      }

      input[type="submit"] {
        font-size: 1rem;
        width: 20%;
      }
    }
  }
}
