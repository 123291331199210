.header {
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-image: url("../../../assets/header.png");
  min-height: 100vh;

  .title {
    max-width: 900px;
    padding-top: 50px;
    padding-left: 100px;

    font-family: Impact;
    font-style: normal;
    font-weight: 900;
    font-size: 4vw;
    line-height: 132.47%;
    color: #FFFFFF;
  }

  h3 {
    padding-top: 50px;
    padding-left: 100px;
    max-width: 800px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 1.5vw;
    line-height: 34px;
    letter-spacing: 0.07em;
    color: #FFFFFF;
  }

  .newsletter-container {
    width: 500px;
    padding: 50px 0 50px 100px;

    .sign-up-now {
      margin: 20px 0;
      background-color: #28C580;
      color: white;
      width: 200px;
      outline: none;
      border: 0;
      padding: 15px;
      font-size: 1em;
    }
  }
}

.header-logo {
  display: flex;
  justify-content: space-between;
  height: 250px;
  width: 100vw;

  .sml-logo {
    padding-top: 50px;
    padding-left: 100px;

    img {
      width: 250px;
    }
  }
}

.header-gradient {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background: radial-gradient(42.49% 63.74% at 50% 50%, rgba(20, 36, 76, 0.8) 0%, rgba(0, 0, 38, 0.8) 100%);
}

@media (min-width: 1600px) {
  .header {
    .title {
      max-width: 75em;
    }
    .subtitle {
      line-height: 200%;
    }
    h3 {
      max-width: 30em;
    }
  }
}

@media (max-width: 768px) {
  .header {
    height: 100vh;
    background-size: auto;
    background-image: url('../../../assets/header_small.png');

    .title {
      font-size: 3em;
      width: 600px;
    }

    .subtitle {
      width: 600px;
      font-size: 1.2em;
    }
  }

  .header-logo {
    .sml-logo {

      img {
        width: 250px;
        height: auto;
      }
    }
  }
}

@media (max-width: 480px) {
  .header {
    height: 110vh;

    .title {
      padding-left: 20px;
      padding-top: 0;
      font-size: 2em;
      width: 320px;
    }

    .subtitle {
      padding-left: 20px;
      width: 320px;
      font-size: 1em;
    }

    .newsletter-container {
      width: 300px;
      margin-top: 40px;
      padding-left: 20px;
      padding-top: 0;
    }
  }

  .header-logo {
    height: 150px;
    .sml-logo {
      padding-top: 30px;
      padding-left: 20px;

      img {
        height: auto;
        width: 175px;
      }
    }
  }

  .header-gradient {
    display: flex;
    flex-direction: column;
    background: radial-gradient(42.49% 63.74% at 50% 50%, rgba(20, 36, 76, 0.8) 0%, rgba(0, 0, 38, 0.8) 100%);
  }
}

