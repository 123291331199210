.companyCheckbox {
  margin-left: 10px;
}

.priceDetailsStyle {
  border-width: 1px;
  border-style: dashed;
  border-color: #bbb;
}

.fieldsetMargin {
  margin: 20px;
}
