.workshop-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Impact;
  padding: 50px 200px 20px 150px;

  .videos {
    max-width: 1200px;
    margin: auto;
    padding: 0 0 50px 0;
    .videos-container {
      padding: 20px;
      display: flex;
      justify-content: center;

      a + a {
        margin-left: 10px;
      }
    }
  }

  .resources {
    max-width: 1200px;
    margin: auto;
    width: 100%;
  }
  .tickets {
    max-width: 1200px;
    margin: auto;
    width: 100%;
  }
}

.video-intro-image {
  width: 100%;
}

.youtube-outer-container {
  position: relative;
  background-color: white;

  .youtube-inner-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  .dark {
    background: rgba(1, 1, 1, 0.5);
    color: white;
  }
}

.youtube-button {
  width: 150px;
}

.tickets-button {
  margin: 20px 0;
  background-color: #000042;
  color: white;
  width: 200px;
  outline: none;
  border: 0;
  padding: 15px;
  font-size: 1em;
}

.tickets-button-light {
  font-family: Montserrat;
  margin-top: 30px;
  margin-left: 100px;
  background-color: #FFB129;
  color: white;
  width: 250px;
  outline: none;
  border: 0;
  padding: 15px;
  font-size: 1em;
}
@media (max-width: 768px) {
  .workshop-content {
    padding: 50px 40px 20px 50px;

    .videos {
      .videos-container {
        display: flex;
        flex-direction: column;
        padding: 20px;
        justify-content: space-around;
        flex-flow: wrap;

        .youtube-outer-container {
          margin-top: 20px;
        }

        a + a {
          margin-left: 0;
        }
      }
    }
  }
  .tickets-button-light {
    font-family: Impact;
    margin-top: 30px;
    margin-left: 20px;
    background-color: #FFB129;
    color: white;
    width: 280px;
    outline: none;
    border: 0;
    padding: 15px;
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .workshop-content {
    display: flex;
    flex-direction: column;
    padding: 50px 10px 20px 10px;

    .videos {
      padding: 0 0 50px 0;

      .videos-container {
        display: flex;
        flex-direction: column;
        padding: 20px 10px 0 10px;
        justify-content: space-between;
        align-content: space-between;
        flex-flow: wrap;
      }
    }

    .youtube-button {
      width: 100px;
    }
  }
}
