.App {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.Main {
  flex: 1;
}

.common-form {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 768px) {
  .common-form {
    width: 25%;
  }
}
