.agenda {
  font-family: Impact;
  padding: 0 0 50px 0;
  max-width: 1200px;
  margin: auto;

  .agenda-item {
    display: flex;

    span {
      font-size: 1.7em;
      font-weight: 900;
    }
  }

  li {
    font-family: Montserrat;
    font-size: 1em;
  }
}

@media (max-width: 768px) {
  .agenda {
    padding: 0 0 20px 0;

    li {
      padding: 0 10px;
    }
  }
}

@media (max-width: 480px) {
  .agenda {
    padding: 0 0 20px 0;

    .agenda-item {
      span {
        font-size: 1.5em;
      }
    }
  }
}
