.akka-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .akka-banner {
    height: 100%;
    background-size: cover;
    background-image: url('../../assets/andrzej_header.png');
  }

  //.videos {
  //  padding: 0 0 50px 0;
  //
  //  .videos-container {
  //    display: flex;
  //    justify-content: space-around;
  //  }
  //}
}


