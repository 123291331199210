.footer-container {
  width: 100%;
  background: radial-gradient(42.49% 63.74% at 50% 50%, #14244C 0%, #000026 100%),
  radial-gradient(42.49% 63.74% at 50% 50%, rgba(20, 36, 76, 0.6) 0%, rgba(0, 0, 38, 0.6) 100%);
  background-size: cover;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10% 10%;
  z-index: 2;

  .company-info {
    display: flex;
    flex-direction: column;
    text-align: center;
    a { color: white;}
  }

  .footer-newsletter {
    width: 400px;
    padding: 20px;

    .sign-up-now {
      margin: 20px 0;
      background-color: #28C580;
      color: white;
      width: 200px;
      outline: none;
      border: 0;
      padding: 15px;
      font-size: 1em;
    }
  }

  .app-version {
    display: none;
  }
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    padding-top: 4em;

    .footer-logo {
      padding-bottom: 2em;
    }
  }
}

@media (max-width: 480px) {
  .footer-container {
    padding-left: 0;
    padding-right: 0;
    flex-direction: column;

    p {
      padding-top: 1em;
    }

    .footer-newsletter {
      width: 100%;
    }
  }
}


