.testimonials {
  font-family: Montserrat;
  margin: auto;
  padding: 100px;

  .quote-container {
      padding: 100px;
    background-color: #EFF3F8;
  }

  h3 {
    font-family: Arial;
    font-size: 2em;
  }
}

@media (max-width: 768px) {
  .testimonials {
    padding: 50px;

    .quote-container {
      padding: 50px;
      background-color: #EFF3F8;
    }

    h3 {
      font-size: 1.2em;
    }
  }
}

@media (max-width: 480px) {
  .testimonials {
    padding: 10px;
    .quote-container {
      padding: 50px;
      background-color: #EFF3F8;
    }

    h3 {
      font-size: 1em;
    }
  }
}
