.trainer-container {
  color: white;
  height: 600px;
  background-image: url('../assets/1.png');

  .about {
    font-family: Impact;
    display: flex;
    flex-direction: column;
    padding-right: 200px;

    h3 {
      font-family: Montserrat;
      width: 600px;
      letter-spacing: 0.01em;
    }
  }

  .photo-container {
    margin-left: 200px;
    width: 350px;
    height: 400px;
    border: 1px solid #9F7533;

    img {
      height: 500px;
      margin-top: -102px;
    }
  }

  .trainer-gradient {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    background: radial-gradient(42.49% 63.74% at 50% 50%, #14244C 0%, #000026 100%),
    radial-gradient(42.49% 63.74% at 50% 50%, rgba(20, 36, 76, 0.6) 0%, rgba(0, 0, 38, 0.6) 100%);


  }

  .social-media {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1.5em;
    padding: 20px;

    a {
      text-decoration: none;
      color: white;
    }
  }

  .trainer-stripes {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 612px;
    height: 800px;
    background: url("../assets/trainer-stripes.png");
  }
}

@media (max-width: 768px) {
  .trainer-container {
    height: 800px;

    .trainer-gradient {
      display: flex;
      flex-direction: column;

      img {
        height: 70%;
      }
    }

    .photo-container {
      margin: 100px auto 10px auto;
      width: 350px;
      height: 450px;
      border: 1px solid #9F7533;

      img {
        height: 503px;
      }
    }

    .about {
      width: 100%;
      padding: 20px;
      margin-bottom: 50px;

      h1 {
        font-size: 2.5em;
      }

      h3 {
        width: 100%;
        font-size: 1.4em;
      }
    }
  }
}

@media (max-width: 480px) {
  .trainer-container {
    min-height: 650px;

    .trainer-gradient {

      img {
        height: 60%;
      }
    }

    .photo-container {
      margin: 100px auto 0 auto;
      width: 300px;
      height: 350px;
      border: 1px solid #9F7533;

      img {
        height: 450px;
      }
    }

    .about {
      width: 100%;
      padding: 20px;

      h1 {
        font-size: 2em;
      }

      h3 {
        width: 100%;
        font-size: 1em;
      }
    }

    .trainer-stripes {
      display: none;
    }
  }
}


