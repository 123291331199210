.event-sourcing-container {
  .videos {
    padding: 0 0 50px 0;
    max-width: 1200px;
    margin: auto;

    img {
      padding-left: 10px;
    }

    .videos-container {
      padding: 10px;
      display: flex;
      justify-content: center;
    }
  }
}
