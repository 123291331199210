.gatling-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .gatling-banner {
    height: 100vh;
    background-size: cover;
  }
}


