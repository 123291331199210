.main-workshop {
  color: black;
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  max-width: 1600px;
  margin: auto;

  .check-for-more {
    margin: 20px 0;
    background-color: #000042;
    color: white;
    width: 200px;
    outline: none;
    border: 0;
    padding: 15px;
    font-size: 1em;
  }

  h1 {
    font-family: Impact;
    font-weight: 700;
    font-size: 4vw;
    padding: 100px 0 50px 150px;
  }

  .video-container {
    padding: 0 200px 20px 150px;
    //img {
    //  width: 100%;
    //}
  }

  .tile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 200px 10px 150px;

    .tile-column {
      display: flex;
    }

    .main-workshop-row {
      font-family: Montserrat;
      padding: 2em 50px 2em 50px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      background-color: #EFF3F8;

      div + div {
        margin-left: 10px;
      }
      .column {
        display: flex;
        flex-basis: 100%;
        flex: 2;

        .card {
          padding: 10px;
          border: none;
          background: none;

          .card-content {
            font-size: 1em;
            font-weight: 900;
            letter-spacing: 0.05em;
          }
        }
      }

      .logo-column {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;

        .card {
          border: none;
        }
      }
    }


    h4 {
      padding: 50px;
    }
  }

  h3 {
    padding: 50px 0;
    font-family: Impact;
    font-weight: 700;
  }

  .workshop-logo {
    text-align: center;
    background-color: #EFF3F8;

    img {
      max-width: 90%;
      height: auto;
    }
  }

}

@media (max-width: 768px) {
  .main-workshop {
    h1 {
      font-size: 3em;
      padding: 25px 0 50px 25px;
    }

    .tile {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 10px;

      .tile-column {
        display: flex;
      }

      .main-workshop-row {
        padding-left: 0;
        padding-top: 5em;
        padding-bottom: 5em;
        .column {
          padding: 10px;

          .card {
            padding: 0 0 0 20px;
            border: none;
            background: none;

            .card-content {
              font-size: 1em;
              font-weight: 700;
            }
          }
        }

        .logo-column {
          display: none;

          .card {
            border: none;
          }
        }
      }
    }

    .check-for-more {
      margin-left: 30px;
      background-color: #000042;
      color: white;
      width: 200px;
      outline: none;
      border: 0;
      padding: 15px;
      font-size: 1em;
    }

    .video-container {
      padding: 10px;
      width: 100%;
      margin: auto;
    }
  }

}

@media (max-width: 480px) {
  .main-workshop {

    h1 {
      padding: 20px;
      font-size: 1.5em;
    }


    .tile {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
      background-color: #EFF3F8;

      .tile-column {
        display: flex;
      }

      .main-workshop-row {
        margin-top: 0;
        padding: 2em 0;
        background-color: #EFF3F8;

        .column {
          padding: 0;

          .card {
            padding-left: 10px;
            border: none;
          }
        }

        .logo-column {
          display: none;

          .card {
            border: none;
          }
        }
      }
    }

    .check-for-more {
      margin: 10px;
    }

    .tile-content {
      padding: 0 25px 0 25px;
    }
  }
}
