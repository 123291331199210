.NavBar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #222222;
  height: 50px;
}

.NavBar__link {
  color: #979D9D;
  height: 50px;
  line-height: 50px;
  padding: 0 1em;
  text-decoration: none;
}

.NavBar__link:hover {
  color: #fff;
}

.NavBar__link--active {
  background-color: #000;
  color: #fff;
}

.NavBar__link:nth-child(2) {
  margin-right: auto;
}

.NavBar__link:nth-child(3) {
  margin-left: auto;
}

.NavBar__menu {
  justify-content: space-between;
}

@media (min-width: 481px) {
  .NavBar__link:first-child {
    margin-left: 10%;
  }

  .NavBar__link:last-child {
    margin-right: 10%;
  }
}
